import {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';

import {
  signOut as signOutNextAuth,
  signIn as signInNextAuth,
  getSession,
  useSession,
} from 'next-auth/react';
import { useRouter } from 'next/router';

import { differenceInDays } from 'date-fns';
import Store from 'lib/Store';
import mapReducerActions from 'utils/common/mapReducerActions';

import { useAlerts } from 'hooks/useAlerts';

import config from 'config';
import routes from 'config/routes';

import api from 'api';
import getUser from 'api/user/getUser';
import increaseTokenExpirationDate from 'api/user/increaseTokenExpirationDate';

import initialState from './initialState';
import * as UserReducer from './reducer';
import {
  IUseUserActionsType,
  IUseUserContextData,
  IUseUserProviderProps,
  IUseUserReducerType,
  LoadingActions,
  ISignInProps,
} from './types/reducer';

const authStore = Store(`${config.localStorageKey}-auth`);

const store = Store(`${config.localStorageKey}-user`);

const UseUserContext = createContext<IUseUserContextData>({} as IUseUserContextData);

const UseUserProvider: React.FC<IUseUserProviderProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { data: session } = useSession();
  const { errorMessage, confirmMessage } = useAlerts();

  const accessToken = useMemo(() => session?.user?.accessToken, [session]);

  const [loadingAction, setLoadingAction] = useState<LoadingActions>(null);

  // # Reducer
  const initialData: IUser = store.get() || initialState;
  const [state, dispatch] = useReducer(UserReducer.reducer, { ...initialData });
  const actions: IUseUserActionsType = useMemo(
    () => mapReducerActions(UserReducer.actions, dispatch),
    [],
  );
  const reducer: IUseUserReducerType = useMemo(() => ({ state, actions }), [actions, state]);

  // # Ao atualizar o state, atualiza a store
  useEffect(() => {
    if (reducer.state.email) store.set(reducer.state);
  }, [reducer.state]);

  // # Clear
  const clear = useCallback(() => {
    store.clear();
    reducer.actions.setClear();
  }, [reducer.actions]);

  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

  // Ao carregar a página, verifica se está logado e então define o token no axios

  useEffect(() => {
    if (accessToken) {
      api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      delete api.defaults.headers.common.Authorization;
    }
  }, [accessToken]);

  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

  const { refetch: refreshUserData } = useQuery(
    ['user-data', reducer.state.cacheKey],
    () => getUser({}),
    {
      enabled: !!accessToken,
      refetchInterval: 60000,
      onSuccess: (data) => {
        // Se a api retorna token expirado, então o usuário deve fazer login novamente
        if (data === 'expired-token') {
          store.clearAll();
          return clear();
        }

        // Se não está expirado, verifica se os dados mudaram e desabilita o modal
        if (data && data !== reducer.state) {
          reducer.actions.setProfile(data);
        }

        // Faz mais do que 30 dias que fez login? Se sim, aumenta o tempo de expiração do token
        try {
          const lastLogin = authStore.getItem('last-login');

          if (differenceInDays(new Date(), new Date(String(lastLogin))) > 30) {
            increaseTokenExpirationDate();
            authStore.setItem('last-login', new Date().toISOString());
          }
        } catch (error) {
          console.log(error);
        }
      },
    },
  );

  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

  // Salva as informações do usuário
  const saveUserDataOnReducer = useCallback(
    (data: IUser) => {
      reducer.actions.setProfile(data);
    },
    [reducer.actions],
  );

  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

  const doSignOut = useCallback(() => {
    clear();
    queryClient.invalidateQueries();
    signOutNextAuth();
  }, [clear, queryClient]);

  const handleSignOut = useCallback(
    (callback?: () => void) =>
      confirmMessage({
        message: 'Tem certeza que deseja sair?',
        onYesCallback: () => {
          doSignOut();

          if (callback) callback();
        },
      }),
    [confirmMessage, doSignOut],
  );

  const handleSignIn = useCallback(
    async (props: ISignInProps) => {
      const { login, password, ignoreRedirect, redirectURL } = props;

      setLoadingAction('signin');

      const res: any = await signInNextAuth('credentials', {
        login,
        password,
        redirect: false,
        callbackUrl: `${window.location.origin}`,
      });

      if (res?.error) {
        setLoadingAction(null);
        return errorMessage('Seu usuário ou senha estão errados, tente novamente, por favor.');
      }

      const createdSession = await getSession();
      const token = createdSession?.user?.accessToken;

      api.defaults.headers.common.Authorization = `Bearer ${token}`;

      // # Pré peenche o reducer com as informações do usuário
      const userData = await getUser({ token: accessToken });

      setLoadingAction(null);

      if (userData && userData !== 'expired-token') {
        saveUserDataOnReducer(userData);
      } else {
        delete api.defaults.headers.common.Authorization;
        doSignOut();
        return errorMessage('Seu usuário ou senha estão errados, tente novamente, por favor.');
      }

      authStore.setItem('last-login', new Date().toISOString());

      // # Redirecionamento
      const redirectURLFromParam = redirectURL;
      const redirectURLFromStorage = authStore.getItem(`redirect`);

      let redirectToURL = null;

      // Prioriza redirect pela Storage
      if (redirectURLFromStorage) {
        redirectToURL = redirectURLFromStorage;
      } else if (redirectURLFromParam) redirectToURL = redirectURLFromParam;

      if (!ignoreRedirect) {
        authStore.removeItem(`redirect`);

        return router.push(
          redirectToURL && redirectToURL !== 'undefined' ? redirectToURL : routes.dashboard,
        );
      }
    },
    [accessToken, doSignOut, errorMessage, router, saveUserDataOnReducer],
  );

  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

  const contextValue = useMemo(
    () => ({
      ...reducer.state,
      ...reducer.actions,

      signOut: handleSignOut,
      signIn: handleSignIn,

      clear,
      refreshUserData,

      saveUserDataOnReducer,
      isUserLoggedIn: !!accessToken,

      loadingAction,
    }),
    [
      accessToken,
      clear,
      handleSignIn,
      handleSignOut,
      loadingAction,
      reducer.actions,
      reducer.state,
      refreshUserData,
      saveUserDataOnReducer,
    ],
  );

  return <UseUserContext.Provider value={contextValue}>{children}</UseUserContext.Provider>;
};

const useUser = (): IUseUserContextData => {
  const context = useContext(UseUserContext);
  if (!context) throw new Error('useUser must be used within an UseUserProvider');
  return context;
};

export { UseUserProvider, useUser };

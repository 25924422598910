import { signOut as signOutNextAuth, getSession } from 'next-auth/react';

import { logError } from 'lib/errorLog';
import Store from 'lib/Store';
import { v4 as uuid } from 'uuid';

import config from 'config';

import axios from './index';

export const mainAPIURL = process.env.NEXT_PUBLIC_API_URL;
export const internalAPIURL = `${process.env.NEXT_PUBLIC_SITE_URL}/api`;

const store = Store(`${config.localStorageKey}-user`);

// * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const doRequest = ({
  path,
  method,
  contentType = 'application/json; charset=UTF-8',
  params,
  token,
}: IDoRequestProps): Promise<any> => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  let paramsProps = params;

  if (contentType === 'application/x-www-form-urlencoded; charset=UTF-8') {
    paramsProps = Object.entries(params)
      .map(([key, val]: any) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
      .join('&');
  }

  return axios(path, {
    method,
    headers: {
      Accept: '*/*',
      'Content-Type': `${contentType}`,
    },
    validateStatus: () => true,
    ...(method !== 'GET'
      ? {
          data: params ? paramsProps : undefined,
        }
      : {}),
  });
};

// * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export async function callAPI(requestProps: ICallAPIProps): Promise<IAPIReturn> {
  const { callId, path, method, disableLog } = requestProps;

  try {
    let response = await doRequest(requestProps);

    // Verifica se ainda está com token válido
    if (response.status === 401) {
      // Tem token na sessão?
      const session = await getSession();

      if (session?.user.accessToken) {
        response = await doRequest({
          ...requestProps,
          token: session.user.accessToken,
        });
      }

      if (response.status !== 401) {
        return {
          error: null,
          request: response.data.Data,
        };
      }

      // Desloga
      store.clearAll();
      signOutNextAuth();

      return {
        error: 'expired-token',
        errorCode: '401',
        request: null,
      };
    }

    if (response.status === 200) {
      return {
        error: null,
        request: response.data.Data,
      };
    }

    // Se caiu aqui, algo deu errado, então faz log
    if (!disableLog) {
      logError({
        message: `Erro #${callId || uuid()} - ${process.env.NEXT_PUBLIC_SITE_URL}`,
        data: response,
        id: callId || uuid(),
        method,
        path,
      });
    }

    return {
      error: 'server',
      errorMessage: response?.data?.Error?.Description || '',
      errorCode: response?.data?.Error?.Code || '',
      request: response,
    };
  } catch (error) {
    if (!disableLog) {
      logError({
        message: `Erro #${callId || uuid()} - ${process.env.NEXT_PUBLIC_SITE_URL}`,
        data: error,
        id: callId || uuid(),
        method,
        path,
      });
    }

    return {
      error: 'server',
      request: null,
    };
  }
}

/* eslint-disable prefer-destructuring */
import { produce } from 'immer';

import initialState from './initialState';
import { IUseUserReducerStateType, IUseUserSetProfileProps } from './types/reducer';

const actions: any = {
  setClear: (): IUseUserReducerStateType => initialState,

  setProfile: (
    state: IUseUserReducerStateType,
    params: IUseUserSetProfileProps,
  ): IUseUserReducerStateType => {
    state = params;
    return state;
  },
};

function reducer(
  state: IUseUserReducerStateType,
  action: IReducerGenericActionsType,
): IUseUserReducerStateType {
  return produce(state, (draftState: IUseUserReducerStateType) =>
    action.type ? actions[action.type](draftState, action.params) : draftState,
  );
}

export { actions, reducer };

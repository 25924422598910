import { IUseUserReducerStateType } from './types/reducer';

const initialState: IUseUserReducerStateType = {
  id: '',

  name: '',
  email: '',
  phone: '',

  terminal: {
    color: '',
    machine: '',
    usage: '',
  },
};

export default initialState;

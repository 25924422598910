export default (str: string): string => {
  if (!str || str === '') return '';

  const ignoredWords = ['de', 'da', 'do', 'que', 'dos', 'das', 'em', 'na', 'no', 'nas', 'nos'];

  return str.replace(/\w\S*/g, (txt) => {
    if (txt.length === 1) return txt.toLowerCase();

    if (ignoredWords.includes(txt.toLowerCase())) return txt.toLowerCase();

    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

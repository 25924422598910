import capitalizeString from 'utils/common/capitalizeString';
import getValueFromObjectPropertyArray from 'utils/common/getValueFromObjectPropertyArray';
import { v4 as uuid } from 'uuid';

interface IMiddlewareUserProps {
  userData: any;
  userProps: any;
}

export const middlewareUser = ({ userData, userProps }: IMiddlewareUserProps): IUser => {
  const userUuid = uuid();

  // Retorno
  return {
    cacheKey: userData?.Id || userUuid,

    id: userData?.Id || userUuid,
    name: userData?.Name ? capitalizeString(userData.Name) : '',
    email: userData?.Email || '',
    phone: userData?.Phone || '',

    terminal: {
      machine: getValueFromObjectPropertyArray(userProps, 'TERMINAL-MACHINE', ''),
      color: getValueFromObjectPropertyArray(userProps, 'TERMINAL-COLOR', ' '),
      usage: getValueFromObjectPropertyArray(userProps, 'TERMINAL-USAGE', ''),
    },
  };
};

export const middlewareObjectProperty = (data: any): IObjectProperty => ({
  key: data?.Key || '',
  value: data?.Value || '',
});

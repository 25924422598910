import { callAPI, mainAPIURL } from 'api/main';

import { middlewareObjectProperty, middlewareUser } from '../middlewares';

type IGetUserProps = IAPIDefaultFields;

export default async (props: IGetUserProps): Promise<IUser | null | 'expired-token'> => {
  try {
    const res = await callAPI({
      ...props,
      disableLog: true,
      callId: 'getUser',
      path: `${mainAPIURL}/v2/person/details`,
      method: 'GET',
    });

    console.log({ res });

    // Verifica se veio com token expirado antes
    if (res.error) {
      return 'expired-token';
    }

    if (res && !res.error && res.request) {
      const resProps = await callAPI({
        ...props,
        callId: 'getUser:user-props',
        path: `${mainAPIURL}/v2/person/property/list`,
        method: 'GET',
      });

      let userProps: IObjectProperty[] = [];

      if (resProps.request && resProps.request.length > 0) {
        userProps = resProps.request.map(middlewareObjectProperty);
      }

      return middlewareUser({
        userData: res.request,
        userProps,
      });
    }

    return null;
  } catch (ex) {
    return null;
  }
};
